import Logo from "@vendor-portal-public/logo_white.svg";
import LogoSmall from "@vendor-portal-public/logo_small_white_new.svg";
import XIcon from "@heroicons/react/outline/XIcon";
import { FixedCenteredLoader } from "@novel/shared/components/CenteredLoader";
import { vendorPortalPrefix } from "@novel/shared/utils/appConstants";
import { isInIFrame } from "@novel/shared/utils/isInIframe";
import { Collapsible, Frame, Navigation } from "@shopify/polaris";
import { GatedIcon, HamBurgerIcon, PlusIcon, StandaloneIcon, TokenIcon, MemberPass, PassDistribution, PassHolderIcon, EngageIcon, CogIcon, BarGraphIcon, PassSetupIcon, PassSetupIcon1, PassSetupIcon2, PassSetupIcon3, PassSetupIcon4, PassSetupIcon5 } from "@vendor-portal/components/icons";
import { createNavQueryString } from "@vendor-portal/definitions/routeDefinitions";
import { useActiveOrganizationMyShopifyDomain, useActiveOrganizationSelector } from "@vendor-portal/redux/reducers/vendorUserOrganizations";
import { useNovelVendorSelector, useUserDisplayName } from "@vendor-portal/redux/reduxHooks";
import { openVendorPortalInStandaloneTab } from "@vendor-portal/redux/utils/authRedirectUtils";
import cx from "classnames";
import { capitalize, cloneDeep, trimEnd } from "lodash";
import Image from "next/legacy/image";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { animated } from "@react-spring/web";
import ConfigIcon from "@vendor-portal/components/icons/ConfigIcon";
import { Box } from "@novel/shared/components/Box";
import { AppEnv, AppEnvEnum } from "@novel/shared/utils/env";
import { BackChevron } from "@novel/shared/icons";
import { IconWrapper } from "@novel/shared/components/PolarisIconWrapper";
import { useRouter } from "next/router";
import { openInNewTab } from "@novel/shared/utils/locationUtils";
import { useIsDemoShopSelector } from "@vendor-portal/redux/reducers/shopifyShopData";
import { useIsSuperUserSelector } from "@vendor-portal/redux/reducers/auth";
interface Props {
  readonly children?: React.ReactNode;
}
export const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stopColor="#333" offset="20%" />
      <stop stopColor="#222" offset="50%" />
      <stop stopColor="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#333" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;
export const toBase64 = (str: string) => typeof window === "undefined" ? Buffer.from(str).toString("base64") : window.btoa(str);
export const VendorPortalLayoutContext = React.createContext<{
  data: IVendorPortalNav[];
  dispatchKeyReducer: React.Dispatch<VendorPortalNavActions>;
}>({
  data: [],
  dispatchKeyReducer: () => undefined
});
interface INavItem {
  label: string;
  icon?: any;
  url: string;
  selected?: boolean;
}
export interface IVendorPortalNav extends INavItem {
  subNavigation?: INavItem[];
  subNavigationOpen?: boolean;
}
export enum ActionType {
  ChangeSelectedSubNavigationOpen,
  ChangeSelectedNav,
  ChangePassSetupIconText,
}
export interface ChangeSelectedSubNavigationOpen {
  type: ActionType.ChangeSelectedSubNavigationOpen;
  payload: number;
}
export interface ChangePassSetupIconText {
  type: ActionType.ChangePassSetupIconText;
  payload: {
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    label: string;
    hide: boolean;
  };
}
export interface ChangeSelectedNav {
  type: ActionType.ChangeSelectedNav;
  payload: {
    clean: string;
    raw: string;
  };
}
export type VendorPortalNavActions = ChangeSelectedSubNavigationOpen | ChangeSelectedNav | ChangePassSetupIconText;
export const vendorPortalNavReducer = (state: IVendorPortalNav[], action: VendorPortalNavActions): IVendorPortalNav[] => {
  switch (action.type) {
    case ActionType.ChangeSelectedSubNavigationOpen:
      {
        const newState = cloneDeep(state).map(item => ({
          ...item,
          subNavigationOpen: false
        }));

        // clear existing selected
        newState.flatMap(item => [item, ...(item?.subNavigation || [])]).forEach(item => {
          item.selected = false;
        });
        if (action.payload >= 0) {
          newState[action.payload].subNavigationOpen = true;
          const firstItem = newState[action.payload].subNavigation?.[0];
          if (firstItem) {
            firstItem.selected = true;
          }
        }
        return newState;
      }
    case ActionType.ChangePassSetupIconText:
      {
        // const newState = state.slice(0).map((item) => ({ ...item, subNavigationOpen: false }));
        const newState = state.map(item => {
          if (item.url.includes("/pass-setup")) {
            return {
              ...item,
              label: action.payload.label,
              icon: action.payload.icon
            };
          }
          return item;
        });
        if (action.payload.hide) {
          const index = newState.findIndex(item => item.url.includes("/pass-setup"));
          if (index >= 0) {
            newState.splice(index, 1);
          }
        }
        return newState;
      }
    case ActionType.ChangeSelectedNav:
      {
        const newState = cloneDeep(state).map(navItem => {
          const cleanedNavPath = trimEnd(navItem.url ? navItem.url.split("?")[0] : "", "/").split("/")[1];
          return {
            ...navItem,
            subNavigation: navItem?.subNavigation?.map(subNavItem => {
              const cleanedNavPath = subNavItem.url.split("?")[0];
              const cleanedSubRaw = action.payload.raw.split("/", 3).join("/");
              return {
                ...subNavItem,
                selected: typeof window === "undefined" ? false : cleanedSubRaw === cleanedNavPath
              };
            }) || undefined,
            selected: typeof window === "undefined" ? false : action.payload.clean === cleanedNavPath,
            subNavigationOpen: action.payload.clean === cleanedNavPath
          };
        });
        return newState;
      }
    default:
      {
        return state;
      }
  }
};
export function VendorPortalLayout({
  children
}: Props): JSX.Element {
  const organizationIsLoading = useNovelVendorSelector(state => state.vendorOrganizations.isLoading);
  const passStep = useNovelVendorSelector(state => state.passWelcomeStep.passStep);
  const welcomeHide = useNovelVendorSelector(state => state.passWelcomeStep.welcomeHide);
  const isDemoShop = useIsDemoShopSelector();
  const isSuperUser = useIsSuperUserSelector();
  const router = useRouter();
  const childRef = React.useRef<any>();
  const activeOrg = useActiveOrganizationSelector();
  const myShopifyDomain = useActiveOrganizationMyShopifyDomain();
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const openLoginPage = useCallback(() => openInNewTab(`/vendor/login${myShopifyDomain ? `?shop=${myShopifyDomain}` : ""}`), [myShopifyDomain]);
  const [open, setOpen] = useState(false);
  const navQueryString = createNavQueryString();
  const cleanedWindowPath = typeof window === "undefined" ? "" : router.pathname.split("/")[1];
  const windowPath = typeof window === "undefined" ? "" : router.pathname;
  const userDisplayName = useUserDisplayName();
  const onNavigationToggle = useCallback(() => {
    setMobileNavigationActive(!mobileNavigationActive);
  }, [mobileNavigationActive]);
  const passSetupIcon = useCallback(() => {
    if (passStep === 1) {
      return PassSetupIcon1;
    } else if (passStep === 2) {
      return PassSetupIcon2;
    } else if (passStep === 3) {
      return PassSetupIcon3;
    } else if (passStep === 4) {
      return PassSetupIcon4;
    } else if (passStep === 5) {
      return PassSetupIcon5;
    }
    return PassSetupIcon;
  }, [passStep]);
  const initialNavData: IVendorPortalNav[] = useMemo(() => {
    return [...(!activeOrg?.canSeeWalletPass && AppEnv !== AppEnvEnum.development ? [] : [...(welcomeHide || isDemoShop ? [] : [{
      label: `Pass Setup ${passStep} of 5`,
      icon: passSetupIcon(),
      url: `/pass-setup${navQueryString}`
    }]), {
      label: "Analytics",
      icon: BarGraphIcon,
      url: `/analytics${navQueryString}`
    },
    //       ]),
    {
      label: "Wallet Pass",
      icon: MemberPass,
      url: `/wallet-pass${navQueryString}`
    }, {
      label: "Pass Distribution",
      icon: PassDistribution,
      url: `/pass-distribution${navQueryString}`,
      subNavigationOpen: false,
      subNavigation: [{
        label: "Post Purchase",
        url: `/pass-distribution/post-purchase${navQueryString}`
      }, {
        label: "Email & SMS",
        url: `/pass-distribution/distribution${navQueryString}`
      }, {
        label: "Source Tags",
        url: `/pass-distribution/source-tags${navQueryString}`
      }]
    }, {
      label: "Pass Holders",
      icon: PassHolderIcon,
      url: `/passholder${navQueryString}`
    }, {
      label: "Engage",
      icon: EngageIcon,
      url: `/engage${navQueryString}`,
      subNavigationOpen: false,
      subNavigation: [{
        label: "Push Notifications",
        url: `/engage/push-group${navQueryString}`
      }, {
        label: "Links",
        url: `/engage/link${navQueryString}`
      }, {
        label: "Smart Push Notifications",
        url: `/engage/smart-push-group${navQueryString}`
      }, {
        label: "Location-Based Messaging",
        url: `/engage/location${navQueryString}`
      }]
    }]), ...(!activeOrg?.canSeeWeb3 && AppEnv !== AppEnvEnum.development ? [] : [{
      label: "Web3 Collections",
      icon: TokenIcon,
      url: `/web3-collection${navQueryString}`
    }]), {
      label: "Gated Experiences",
      icon: GatedIcon,
      url: `/gated-experience${navQueryString}`
    }, {
      label: "Settings",
      icon: ConfigIcon,
      url: `/configure${navQueryString}`
    }, {
      label: "Integrations",
      icon: CogIcon,
      url: `/integration${navQueryString}`
    }];
  }, [isDemoShop, activeOrg, navQueryString, passSetupIcon, passStep, welcomeHide]);
  const [navData, dispatchNavReducer] = useReducer(vendorPortalNavReducer, initialNavData);
  useEffect(() => {
    dispatchNavReducer({
      type: ActionType.ChangePassSetupIconText,
      payload: {
        label: `Pass Setup ${passStep} of 5`,
        icon: passSetupIcon()!,
        hide: welcomeHide
      }
    });
  }, [passStep, passSetupIcon, welcomeHide]);
  const orgDisplayName = useMemo(() => (activeOrg?.orgName || "").replace(/-|_/g, " ").split(" ").map(capitalize).join(" "), [activeOrg?.orgName]);
  useEffect(() => {
    dispatchNavReducer({
      type: ActionType.ChangeSelectedNav,
      payload: {
        clean: cleanedWindowPath,
        raw: windowPath
      }
    });
  }, [cleanedWindowPath, windowPath]);
  const [loaded, setLoaded] = useState(false);
  const navigationMarkup = useMemo(() => <div className="novel-config-nav-wrapper">
                <Navigation location={vendorPortalPrefix}>
                    <div className="novel-all-nav-items-container">
                        <div className="cross-icon">
                            <XIcon height={20} width={20} onClick={onNavigationToggle} className="cursor-pointer" />
                        </div>
                        <span className="logoAreaFixed">
                            <div className="full-logo-container">
                                <Image src={Logo} alt="Logo" blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(70, 70))}`} layout="fill" objectFit="contain" style={{
              margin: 0,
              width: "75%"
            }} />
                                <IconWrapper icon={BackChevron} color="#858585" style={{
              cursor: "pointer",
              height: "15px",
              width: "15px"
            }} onClick={e => {
              e.stopPropagation();
              setOpen(false);
              setLoaded(false);
            }} />
                            </div>

                            <div className="small-logo-container">
                                <Image src={LogoSmall} alt="LogoSmall" blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(70, 70))}`} layout="fill" objectFit="scale-down" priority />
                            </div>
                        </span>
                        <Box column mt={3} height={"100%"}>
                            {navData.map((item, index) => <Box key={index} justifyContent={open ? "flex-start" : "center"} columnGap={1} alignItems={"center"} className={cx("navigation-item", {
            "navigation-item-selected": item.selected,
            "navigation-item-selected-sub": item.selected && item.subNavigation && open
          })} flexWrap="wrap" onClick={() => {
            if (item.subNavigation) {
              router.push(item.subNavigation[0].url as any);
              dispatchNavReducer({
                type: ActionType.ChangeSelectedSubNavigationOpen,
                payload: index
              });
            } else {
              router.push(item.url as any);
              dispatchNavReducer({
                type: ActionType.ChangeSelectedSubNavigationOpen,
                payload: -1
              });
            }
          }}>
                                    {open && <div style={{
              paddingLeft: "0.5rem"
            }} />}
                                    <item.icon style={{
              width: "20px",
              height: "20px"
            }} stroke={"white"} />
                                    {open && loaded && <React.Fragment>
                                            <Box flexGrow={1} style={{
                fontSize: "12px"
              }} className={cx("nav-label", {
                "nav-label-selected": item.selected
              })}>
                                                {item.label}
                                            </Box>

                                            {item.subNavigation && <React.Fragment>
                                                    <div style={{
                  paddingRight: "12px"
                }}>
                                                        {(!item.subNavigationOpen || !navData[index].selected) && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#858585" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>}
                                                    </div>
                                                    <div style={{
                  display: "flex",
                  flexBasis: "100%"
                }} />
                                                    <Collapsible open={navData[index].subNavigationOpen || navData[index].selected || false} id="basic-collapsible" transition={{
                  duration: "500ms",
                  timingFunction: "ease-in-out"
                }}>
                                                        <Box column pt={0.5}>
                                                            {item.subNavigation.map(subItem => {
                      return <Box flexGrow={1} key={subItem.label} className={cx("sub-navigation-item", {
                        "sub-navigation-item-selected": subItem.selected
                      })} style={{
                        fontSize: "12px",
                        fontWeight: "normal",
                        width: "100%",
                        color: "white"
                      }} pl={4.5} pr={1} onClick={e => {
                        e.stopPropagation();
                        router.push(subItem.url as any);
                      }}>
                                                                        <Box className={cx("nav-label", {
                          "nav-label-selected": subItem.selected
                        })}>
                                                                            {subItem.label}
                                                                        </Box>
                                                                    </Box>;
                    })}
                                                        </Box>
                                                    </Collapsible>
                                                </React.Fragment>}
                                        </React.Fragment>}
                                </Box>)}
                            <div style={{
            display: "flex",
            flexGrow: 1
          }} />
                            <Box justifyContent={open ? "flex-start" : "center"} alignItems={"center"} columnGap={1} pl={open ? 1.5 : 0} className="navigation-item" onClick={isInIFrame ? openVendorPortalInStandaloneTab : openLoginPage}>
                                <StandaloneIcon style={{
              width: "20px",
              height: "20px"
            }} color="white" />
                                {open && <div style={{
              fontSize: "12px",
              color: "white"
            }}>
                                        {isInIFrame ? "Standalone Mode" : orgDisplayName}
                                    </div>}
                            </Box>
                            <Box justifyContent={open ? "flex-start" : "center"} alignItems={"center"} columnGap={1} pl={open ? 1 : 0} className="navigation-item">
                                <div className="novel-config-user-circle user-circle-skin-black">
                                    {userDisplayName.slice(0, 1)}
                                </div>
                                {open && <div style={{
              fontSize: "12px",
              color: "white"
            }}>
                                        {userDisplayName}
                                    </div>}
                            </Box>
                        </Box>
                    </div>
                </Navigation>
            </div>, [onNavigationToggle, navData, open, openLoginPage, orgDisplayName, userDisplayName, loaded, router,
  // memoize based on actively selected
  // eslint-disable-next-line react-hooks/exhaustive-deps
  navData.flatMap(item => [item.selected ? 1 : 0, ...(item.subNavigation?.map(subNavItem => subNavItem.selected ? 1 : 0) || [])]).join("")]);
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // ignoring types as we pass this with the knowledge of the props
      return React.cloneElement(child, {
        childRef: childRef as any,
        open
      } as any);
    }
    return child;
  });
  return <div className={cx("frame-wrapper")}>
            <Frame>
                <div className={`backdrop ${mobileNavigationActive ? "flex" : "hidden"}`}>
                    <aside className="h-screen overflow-hidden flex-col bg-white aside-width py-8 min-h-screen z-30 absolute">
                        <LeftNavigationPane children={navigationMarkup} mobile />
                    </aside>
                </div>
                <nav className="topBarMobile">
                    <div className="max-w-6xl mx-auto px-4 h-full p-4">
                        <div className="navbarTop">
                            <HamBurgerIcon style={{
              cursor: "pointer"
            }} onClick={onNavigationToggle} />
                            <div>
                                <Image src={Logo} alt="Logo" objectFit="contain" width={100} height={30} />
                            </div>
                            <div className="novel-config-user-circle user-circle-skin-white" style={{
              height: "34px",
              width: "34px"
            }} onClick={() => childRef?.current()}>
                                <PlusIcon width={20} height={20} />
                            </div>
                        </div>
                    </div>
                </nav>

                <LeftNavigationPane open={open} children={navigationMarkup} setOpen={setOpen} setLoaded={setLoaded} />
                <animated.div className="main-app-container-wrapper"
      // style={contentProps}
      >
                    <div className="main-app-container-container">
                        {organizationIsLoading ?
          // centering spinner
          <div className="app-loader-container">
                                <FixedCenteredLoader />
                            </div> : childrenWithProps}
                    </div>
                    <div className="footer-wrapper">
                        <div className="footer-container">
                            <div className="copyright-container">© Novel Commerce Inc.</div>
                            <div className="legal-links-container">
                                <a className="legal-link-container" href="https://novel.com/terms" target="_blank">
                                    Terms & Privacy
                                </a>
                            </div>
                        </div>
                    </div>
                </animated.div>
            </Frame>
        </div>;
}
const LeftNavigationPane = ({
  children,
  mobile,
  open,
  setOpen,
  setLoaded
}: {
  readonly children: React.ReactNode;
  readonly mobile?: boolean;
  readonly open?: boolean;
  readonly setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return <React.Fragment>
            {mobile ? <div className="left-navigation-mobile">{children}</div> : <animated.div
    // style={contentProps}
    onClick={() => {
      setOpen!(true);
      setTimeout(() => {
        setLoaded!(true);
      }, 100);
    }} className={`${mobile ? "left-navigation-mobile" : open ? "left-navigation-active" : "left-navigation"}`}>
                    {children}
                </animated.div>}
        </React.Fragment>;
};