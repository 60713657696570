import { IPushGroupWithLinksAndStats } from "@novel/shared/interfaces/PushGroup";
import { NovelVendorSubReducer } from "@vendor-portal/redux/vendorTypedRedux";

export interface IPassPushNotificationReducerState {
    isLoading: boolean;
    data: IPushGroupWithLinksAndStats[];
}

const initialState: IPassPushNotificationReducerState = {
    isLoading: true,
    data: [],
};

export const pushNotificationReducer: NovelVendorSubReducer<IPassPushNotificationReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_PUSH_GROUPS": {
            return {
                ...state,
                isLoading: true,
            };
        }

        case "ERROR_LOADING_PUSH_GROUPS": {
            return {
                ...state,
                isLoading: false,
            };
        }

        case "LOADED_PUSH_GROUPS": {
            return {
                ...state,
                data: action.payload.pushGroupsWithLinksAndStats,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};
